import { Component } from '@angular/core'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'gamin.bar'
  egCount = 0
  fire = false

  egFire() {
    this.egCount++
    if (this.egCount >= 3) {
      this.fire = true
      setTimeout(() => {
        this.fire = false
        this.egCount = 0
      }, 10000)
    }
  }
}
