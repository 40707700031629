<header>
  <pre>
    .____              ________               .__            
    |    |    ____    /  _____/_____    _____ |__| ____      
    |    |  _/ __ \  /   \  ___\__  \  /     \|  |/    \     
    |    |__\  ___/  \    \_\  \/ __ \|  Y Y  \  |   |  \    
    |_______ \___  >  \______  (____  /__|_|  /__|___|  /    
            \/   \/          \/     \/      \/        \/     
  </pre>
</header>

<ng-template #popContentHappyHour>
  Lundi au jeudi: 18h/22h<br />
  Vendredi et Samedi: 18h/21h<br />
  (Sauf veille et jours fériés)<br />
</ng-template>
<ng-template #popTitleHappyHour>
  <h3 class="no-margin">Happy Hour</h3>
</ng-template>

<section>
  <mat-tab-group mat-align-tabs="center">
    <mat-tab>
      <ng-template mat-tab-label>
        <!-- <mat-icon class="tab-icon">thumb_up</mat-icon> -->
        Bière / Shots
      </ng-template>
      <h3>Nos Bières</h3>
      <h4>Pression</h4>
      <mat-grid-list cols="4" rowHeight="20px">
        <mat-grid-tile></mat-grid-tile>
        <mat-grid-tile></mat-grid-tile>
        <mat-grid-tile [ngbPopover]="popContentHappyHour" [popoverTitle]="popTitleHappyHour" popoverClass="popover"
          container="body" class="wrap title">
          Happy Hour
          <mat-icon class="small">info</mat-icon>
        </mat-grid-tile>
        <mat-grid-tile class="title">Normal</mat-grid-tile>

        <mat-grid-tile rowspan="3">Blonde</mat-grid-tile>
        <mat-grid-tile>25cl</mat-grid-tile>
        <mat-grid-tile>3€</mat-grid-tile>
        <mat-grid-tile>4€</mat-grid-tile>

        <mat-grid-tile>50cl</mat-grid-tile>
        <mat-grid-tile>4.50€</mat-grid-tile>
        <mat-grid-tile>6.50€</mat-grid-tile>

        <mat-grid-tile>1L</mat-grid-tile>
        <mat-grid-tile>8.50€</mat-grid-tile>
        <mat-grid-tile>15€</mat-grid-tile>

        <mat-grid-tile colspan="4"></mat-grid-tile>
        <mat-grid-tile rowspan="3">Tripel Karmeliet</mat-grid-tile>
        <mat-grid-tile>25cl</mat-grid-tile>
        <mat-grid-tile></mat-grid-tile>
        <mat-grid-tile>4.50€</mat-grid-tile>

        <mat-grid-tile>50cl</mat-grid-tile>
        <mat-grid-tile></mat-grid-tile>
        <mat-grid-tile>8€</mat-grid-tile>

        <mat-grid-tile>1L</mat-grid-tile>
        <mat-grid-tile></mat-grid-tile>
        <mat-grid-tile>15€</mat-grid-tile>

        <mat-grid-tile colspan="4"></mat-grid-tile>
        <mat-grid-tile>Goos Island IPA</mat-grid-tile>
        <mat-grid-tile>25cl</mat-grid-tile>
        <mat-grid-tile></mat-grid-tile>
        <mat-grid-tile>4.50€</mat-grid-tile>

        <mat-grid-tile></mat-grid-tile>
        <mat-grid-tile>50cl</mat-grid-tile>
        <mat-grid-tile></mat-grid-tile>
        <mat-grid-tile>8€</mat-grid-tile>

        <mat-grid-tile></mat-grid-tile>
        <mat-grid-tile>1L</mat-grid-tile>
        <mat-grid-tile></mat-grid-tile>
        <mat-grid-tile>15€</mat-grid-tile>

        <mat-grid-tile colspan="4"></mat-grid-tile>
        <mat-grid-tile>Kwak</mat-grid-tile>
        <mat-grid-tile>25cl</mat-grid-tile>
        <mat-grid-tile></mat-grid-tile>
        <mat-grid-tile>4.50€</mat-grid-tile>

        <mat-grid-tile></mat-grid-tile>
        <mat-grid-tile>50cl</mat-grid-tile>
        <mat-grid-tile></mat-grid-tile>
        <mat-grid-tile>8€</mat-grid-tile>

        <mat-grid-tile></mat-grid-tile>
        <mat-grid-tile>1L</mat-grid-tile>
        <mat-grid-tile></mat-grid-tile>
        <mat-grid-tile>15€</mat-grid-tile>

        <mat-grid-tile colspan="4"></mat-grid-tile>
        <mat-grid-tile>Hoegaarden blanche</mat-grid-tile>
        <mat-grid-tile>25cl</mat-grid-tile>
        <mat-grid-tile></mat-grid-tile>
        <mat-grid-tile>4.50€</mat-grid-tile>

        <mat-grid-tile></mat-grid-tile>
        <mat-grid-tile>50cl</mat-grid-tile>
        <mat-grid-tile></mat-grid-tile>
        <mat-grid-tile>8€</mat-grid-tile>

        <mat-grid-tile></mat-grid-tile>
        <mat-grid-tile>1L</mat-grid-tile>
        <mat-grid-tile></mat-grid-tile>
        <mat-grid-tile>15€</mat-grid-tile>

        <mat-grid-tile colspan="4"></mat-grid-tile>
        <mat-grid-tile>Licorne black</mat-grid-tile>
        <mat-grid-tile>25cl</mat-grid-tile>
        <mat-grid-tile></mat-grid-tile>
        <mat-grid-tile>4.50€</mat-grid-tile>

        <mat-grid-tile></mat-grid-tile>
        <mat-grid-tile>50cl</mat-grid-tile>
        <mat-grid-tile></mat-grid-tile>
        <mat-grid-tile>8€</mat-grid-tile>

        <mat-grid-tile></mat-grid-tile>
        <mat-grid-tile>1L</mat-grid-tile>
        <mat-grid-tile></mat-grid-tile>
        <mat-grid-tile>15€</mat-grid-tile>

        <mat-grid-tile colspan="4"></mat-grid-tile>
        <mat-grid-tile>Paix dieu</mat-grid-tile>
        <mat-grid-tile>25cl</mat-grid-tile>
        <mat-grid-tile></mat-grid-tile>
        <mat-grid-tile>4.50€</mat-grid-tile>

        <mat-grid-tile></mat-grid-tile>
        <mat-grid-tile>50cl</mat-grid-tile>
        <mat-grid-tile></mat-grid-tile>
        <mat-grid-tile>8€</mat-grid-tile>

        <mat-grid-tile></mat-grid-tile>
        <mat-grid-tile>1L</mat-grid-tile>
        <mat-grid-tile></mat-grid-tile>
        <mat-grid-tile>15€</mat-grid-tile>

        <mat-grid-tile colspan="4"></mat-grid-tile>
        <mat-grid-tile>Barbar</mat-grid-tile>
        <mat-grid-tile>25cl</mat-grid-tile>
        <mat-grid-tile></mat-grid-tile>
        <mat-grid-tile>4.50€</mat-grid-tile>

        <mat-grid-tile></mat-grid-tile>
        <mat-grid-tile>50cl</mat-grid-tile>
        <mat-grid-tile></mat-grid-tile>
        <mat-grid-tile>8€</mat-grid-tile>

        <mat-grid-tile></mat-grid-tile>
        <mat-grid-tile>1L</mat-grid-tile>
        <mat-grid-tile></mat-grid-tile>
        <mat-grid-tile>15€</mat-grid-tile>


      </mat-grid-list>

      <h4>Bouteille</h4>
      <mat-grid-list cols="2" rowHeight="20px">
        <mat-grid-tile colspan="2"></mat-grid-tile>

        <mat-grid-tile>Corona 4.5%</mat-grid-tile>
        <mat-grid-tile>6€</mat-grid-tile>

        <mat-grid-tile>Desperados 5.9%</mat-grid-tile>
        <mat-grid-tile>6€</mat-grid-tile>

        <mat-grid-tile colspan="2"></mat-grid-tile>
        <mat-grid-tile>Chimay bleu 9%</mat-grid-tile>
        <mat-grid-tile>7€</mat-grid-tile>

        <mat-grid-tile>Chouffe 8%</mat-grid-tile>
        <mat-grid-tile>7€</mat-grid-tile>

        <mat-grid-tile>Delirium Tremens 8.5%</mat-grid-tile>
        <mat-grid-tile>7€</mat-grid-tile>

        <mat-grid-tile>Delirium Tremens Red 8.5%</mat-grid-tile>
        <mat-grid-tile>7€</mat-grid-tile>

        <mat-grid-tile>Duvel 8.5%</mat-grid-tile>
        <mat-grid-tile>7€</mat-grid-tile>

        <mat-grid-tile>La démon 12%</mat-grid-tile>
        <mat-grid-tile>7€</mat-grid-tile>
      </mat-grid-list>

      <h3>Nos Shots</h3>
      <h4>Vodka aromatisée</h4>
      <mat-grid-list cols="3" rowHeight="20px">
        <mat-grid-tile></mat-grid-tile>
        <mat-grid-tile class="title">Le shot</mat-grid-tile>
        <mat-grid-tile class="title">Le mètre</mat-grid-tile>

        <mat-grid-tile>Caramel</mat-grid-tile>
        <mat-grid-tile>3.50€</mat-grid-tile>
        <mat-grid-tile>30€</mat-grid-tile>

        <mat-grid-tile>Cerise</mat-grid-tile>
        <mat-grid-tile>3.50€</mat-grid-tile>
        <mat-grid-tile>30€</mat-grid-tile>

        <mat-grid-tile>Melon</mat-grid-tile>
        <mat-grid-tile>3.50€</mat-grid-tile>
        <mat-grid-tile>30€</mat-grid-tile>

        <mat-grid-tile>Passion</mat-grid-tile>
        <mat-grid-tile>3.50€</mat-grid-tile>
        <mat-grid-tile>30€</mat-grid-tile>

        <mat-grid-tile>Violette</mat-grid-tile>
        <mat-grid-tile>3.50€</mat-grid-tile>
        <mat-grid-tile>30€</mat-grid-tile>
      </mat-grid-list>

      <h4>Classiques</h4>
      <mat-grid-list cols="3" rowHeight="20px">
        <mat-grid-tile></mat-grid-tile>
        <mat-grid-tile class="title">Le shot</mat-grid-tile>
        <mat-grid-tile class="title">Le mètre</mat-grid-tile>

        <mat-grid-tile ngbPopover="Bailey's, Get 27" popoverTitle="After Eight" popoverClass="popover">After Eight
          <mat-icon>info</mat-icon>
        </mat-grid-tile>
        <mat-grid-tile>4.50€</mat-grid-tile>
        <mat-grid-tile>40€</mat-grid-tile>

        <mat-grid-tile ngbPopover="Vodka, Get 27" popoverTitle="Kiss Cool" popoverClass="popover">Kiss Cool<mat-icon>
            info</mat-icon>
        </mat-grid-tile>
        <mat-grid-tile>4.50€</mat-grid-tile>
        <mat-grid-tile>40€</mat-grid-tile>

        <mat-grid-tile>Tequila</mat-grid-tile>
        <mat-grid-tile>4.50€</mat-grid-tile>
        <mat-grid-tile>40€</mat-grid-tile>

        <mat-grid-tile>Sambuca</mat-grid-tile>
        <mat-grid-tile>4.50€</mat-grid-tile>
        <mat-grid-tile>40€</mat-grid-tile>

        <mat-grid-tile>Vodka</mat-grid-tile>
        <mat-grid-tile>4.50€</mat-grid-tile>
        <mat-grid-tile>40€</mat-grid-tile>

        <mat-grid-tile>Jagermeister</mat-grid-tile>
        <mat-grid-tile>4.50€</mat-grid-tile>
        <mat-grid-tile>40€</mat-grid-tile>
      </mat-grid-list>

      <h4>Enflammés</h4>
      <mat-grid-list cols="3" rowHeight="20px">
        <mat-grid-tile></mat-grid-tile>
        <mat-grid-tile class="title">Le shot</mat-grid-tile>
        <mat-grid-tile class="title">Le mètre</mat-grid-tile>

        <mat-grid-tile ngbPopover="Liqueur de café, Bailey's, Countreau" popoverTitle="B52" popoverClass="popover">B52
          <mat-icon>info</mat-icon>
        </mat-grid-tile>
        <mat-grid-tile>5€</mat-grid-tile>
        <mat-grid-tile>45€</mat-grid-tile>

        <mat-grid-tile ngbPopover="Liqueur de café, Bailey's, Absynthe" popoverTitle="B68" popoverClass="popover">B68
          <mat-icon>info</mat-icon>
        </mat-grid-tile>
        <mat-grid-tile>5€</mat-grid-tile>
        <mat-grid-tile>45€</mat-grid-tile>

        <mat-grid-tile ngbPopover="Sambuca, Bailey's" popoverTitle="On Fire (Shot enflammé dans la bouche)"
          popoverClass="popover" (click)="egFire()">On Fire
          <mat-icon>info</mat-icon>
        </mat-grid-tile>
        <mat-grid-tile>5€</mat-grid-tile>
        <mat-grid-tile>45€</mat-grid-tile>
      </mat-grid-list>

      <h4>Strong</h4>
      <mat-grid-list cols="3" rowHeight="20px">
        <mat-grid-tile></mat-grid-tile>
        <mat-grid-tile class="title">Le shot</mat-grid-tile>
        <mat-grid-tile class="title">Le mètre</mat-grid-tile>

        <mat-grid-tile>Jack Fire</mat-grid-tile>
        <mat-grid-tile>5€</mat-grid-tile>
        <mat-grid-tile>45€</mat-grid-tile>

        <mat-grid-tile>Jack Honey</mat-grid-tile>
        <mat-grid-tile>5€</mat-grid-tile>
        <mat-grid-tile>45€</mat-grid-tile>

        <mat-grid-tile>Fireball</mat-grid-tile>
        <mat-grid-tile>5€</mat-grid-tile>
        <mat-grid-tile>45€</mat-grid-tile>

        <mat-grid-tile>Chartreuse Verte</mat-grid-tile>
        <mat-grid-tile>5€</mat-grid-tile>
        <mat-grid-tile>45€</mat-grid-tile>

        <mat-grid-tile>Absinthe</mat-grid-tile>
        <mat-grid-tile>5€</mat-grid-tile>
        <mat-grid-tile>45€</mat-grid-tile>

        <mat-grid-tile>Rhum</mat-grid-tile>
        <mat-grid-tile>5€</mat-grid-tile>
        <mat-grid-tile>45€</mat-grid-tile>

        <mat-grid-tile>Whisky</mat-grid-tile>
        <mat-grid-tile>5€</mat-grid-tile>
        <mat-grid-tile>45€</mat-grid-tile>

        <mat-grid-tile colspan="3"></mat-grid-tile>
        <mat-grid-tile>JagerBomb</mat-grid-tile>
        <mat-grid-tile>6€</mat-grid-tile>
        <mat-grid-tile>55€</mat-grid-tile>
      </mat-grid-list>

      <mat-grid-list cols="1" rowHeight="20px">
        <mat-grid-tile></mat-grid-tile>
      </mat-grid-list>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <!-- <mat-icon class="tab-icon">thumb_up</mat-icon> -->
        Cocktails
      </ng-template>
      <h3>Nos Cocktails</h3>
      <h4>Cocktail pour tous</h4>
      <mat-grid-list cols="4" rowHeight="20px">
        <mat-grid-tile colspan="2"></mat-grid-tile>
        <mat-grid-tile [ngbPopover]="popContentHappyHour" [popoverTitle]="popTitleHappyHour" popoverClass="popover"
          container="body" class="wrap title">
          Happy Hour
          <mat-icon class="small">info</mat-icon>
        </mat-grid-tile>
        <mat-grid-tile class="title">Normal</mat-grid-tile>

        <mat-grid-tile colspan="2" ngbPopover="Rhum, menthe fraîche, citron vert, sucre, Angostura, Soda"
          popoverTitle="Mojito" popoverClass="popover">Mojito<mat-icon>info</mat-icon>
        </mat-grid-tile>
        <mat-grid-tile>5.50€</mat-grid-tile>
        <mat-grid-tile>8.50€</mat-grid-tile>

        <mat-grid-tile colspan="2" ngbPopover="Rhum, crème de coco, jus d'ananas" popoverTitle="Piña Colada"
          popoverClass="popover">Piña Colada
          <mat-icon>info</mat-icon>
        </mat-grid-tile>
        <mat-grid-tile>5.50€</mat-grid-tile>
        <mat-grid-tile>8.50€</mat-grid-tile>

        <mat-grid-tile colspan="2" ngbPopover="Cachaça, citron vert, sucre" popoverTitle="Caïpirinha"
          popoverClass="popover">Caïpirinha
          <mat-icon>
            info</mat-icon>
        </mat-grid-tile>
        <mat-grid-tile>5.50€</mat-grid-tile>
        <mat-grid-tile>8.50€</mat-grid-tile>

        <mat-grid-tile colspan="2" ngbPopover="Vodka, crème de mûre, liqueur de melon, jus d'orange, jus de cranberry"
          popoverTitle="Sex on the beach" popoverClass="popover">Sex on the beach<mat-icon>info</mat-icon>
        </mat-grid-tile>
        <mat-grid-tile>5.50€</mat-grid-tile>
        <mat-grid-tile>8.50€</mat-grid-tile>

        <mat-grid-tile colspan="2" ngbPopover="Vodka, triple sec, jus de citron, jus de cranberry"
          popoverTitle="Cosmopolitain" popoverClass="popover">Cosmopolitain<mat-icon>info</mat-icon>
        </mat-grid-tile>
        <mat-grid-tile>5.50€</mat-grid-tile>
        <mat-grid-tile>8.50€</mat-grid-tile>

        <mat-grid-tile colspan="2" ngbPopover="Vodka, bailey's, liqueur de café, liqueur de vanille"
          popoverTitle="Malgache" popoverClass="popover">Malgache<mat-icon>info</mat-icon>
        </mat-grid-tile>
        <mat-grid-tile>5.50€</mat-grid-tile>
        <mat-grid-tile>8.50€</mat-grid-tile>

        <mat-grid-tile colspan="2" ngbPopover="Vodka, curaçao bleu, jus de fruit de la passion" popoverTitle="Aquarium"
          popoverClass="popover">
          Aquarium<mat-icon>info</mat-icon>
        </mat-grid-tile>
        <mat-grid-tile>5.50€</mat-grid-tile>
        <mat-grid-tile>8.50€</mat-grid-tile>

        <mat-grid-tile colspan="2" ngbPopover="Tequila, triple sec, jus de citron, sel" popoverTitle="Margarita"
          popoverClass="popover">
          Margarita
          <mat-icon>info</mat-icon>
        </mat-grid-tile>
        <mat-grid-tile>5.50€</mat-grid-tile>
        <mat-grid-tile>8.50€</mat-grid-tile>

        <mat-grid-tile colspan="2" ngbPopover="Tequilla, jus d'orange, grenadine" popoverTitle="Tequila Sunrise"
          popoverClass="popover">Tequila
          Sunrise<mat-icon>info</mat-icon>
        </mat-grid-tile>
        <mat-grid-tile>5.50€</mat-grid-tile>
        <mat-grid-tile>8.50€</mat-grid-tile>

        <mat-grid-tile colspan="2" ngbPopover="Gin, jus de citron, sucre, soda" popoverTitle="Tom Collins"
          popoverClass="popover">Tom Collins
          <mat-icon>info</mat-icon>
        </mat-grid-tile>
        <mat-grid-tile>5.50€</mat-grid-tile>
        <mat-grid-tile>8.50€</mat-grid-tile>

        <mat-grid-tile colspan="2" ngbPopover="Gin, crème de mûre, jus de fruit de la passion"
          popoverTitle="Pasion Noire" popoverClass="popover">Pasion Noire<mat-icon>info</mat-icon>
        </mat-grid-tile>
        <mat-grid-tile>5.50€</mat-grid-tile>
        <mat-grid-tile>8.50€</mat-grid-tile>

        <mat-grid-tile colspan="2" ngbPopover="Gin, sirop de violette, jus de cranberry" popoverTitle="Pink Lady"
          popoverClass="popover">Pink
          Lady<mat-icon>info</mat-icon>
        </mat-grid-tile>
        <mat-grid-tile>5.50€</mat-grid-tile>
        <mat-grid-tile>8.50€</mat-grid-tile>

        <mat-grid-tile colspan="2" ngbPopover="Whisky, jus de citron, sucre" popoverTitle="Whisky Sour"
          popoverClass="popover">Whisky Sour
          <mat-icon>info</mat-icon>
        </mat-grid-tile>
        <mat-grid-tile>5.50€</mat-grid-tile>
        <mat-grid-tile>8.50€</mat-grid-tile>
      </mat-grid-list>

      <h4>Cocktail pour les curieux</h4>
      <mat-grid-list cols="4" rowHeight="20px">
        <mat-grid-tile colspan="2"></mat-grid-tile>
        <mat-grid-tile [ngbPopover]="popContentHappyHour" [popoverTitle]="popTitleHappyHour" popoverClass="popover"
          container="body" class="wrap title">
          Happy Hour
          <mat-icon class="small">info</mat-icon>
        </mat-grid-tile>
        <mat-grid-tile class="title">Normal</mat-grid-tile>

        <mat-grid-tile colspan="2" ngbPopover="Rhum Havana 7 ans, sirop de miel, jus de citron, bitter"
          popoverTitle="La Cancha" popoverClass="popover">La Cancha<mat-icon>info</mat-icon>
        </mat-grid-tile>
        <mat-grid-tile>6.80€</mat-grid-tile>
        <mat-grid-tile>9.80€</mat-grid-tile>

        <mat-grid-tile colspan="2" ngbPopover="Vodka, framboises fraiches, purée de mangue, jus de cranberry"
          popoverTitle="Boukob" popoverClass="popover">Boukob<mat-icon>info</mat-icon>
        </mat-grid-tile>
        <mat-grid-tile>6.80€</mat-grid-tile>
        <mat-grid-tile>9.80€</mat-grid-tile>

        <mat-grid-tile colspan="2" ngbPopover="Rhum épicé, citron vert, angostura bitter, bitter orange, ginger beer"
          popoverTitle="Dark'n Stormy" popoverClass="popover">Dark'n Stormy<mat-icon>info</mat-icon>
        </mat-grid-tile>
        <mat-grid-tile>6.80€</mat-grid-tile>
        <mat-grid-tile>9.80€</mat-grid-tile>

        <mat-grid-tile colspan="2" ngbPopover="Rhum, gingembre frais, jus d'orange, jus de citron, grenadine"
          popoverTitle="Bennyben" popoverClass="popover">Bennyben<mat-icon>info</mat-icon>
        </mat-grid-tile>
        <mat-grid-tile>6.80€</mat-grid-tile>
        <mat-grid-tile>9.80€</mat-grid-tile>

        <mat-grid-tile colspan="2" ngbPopover="Whisky, gingembre frais, sirop d'érable, jus de citron"
          popoverTitle="Le Sournois" popoverClass="popover">Le Sournois<mat-icon>info</mat-icon>
        </mat-grid-tile>
        <mat-grid-tile>6.80€</mat-grid-tile>
        <mat-grid-tile>9.80€</mat-grid-tile>

        <mat-grid-tile colspan="2" ngbPopover="Vodka, curaçao bleu, jus de fruit de la passion"
          popoverTitle="Jack Lynshburg Limonade" popoverClass="popover">Jack Lynshburg Limonade<mat-icon>info</mat-icon>
        </mat-grid-tile>
        <mat-grid-tile>6.80€</mat-grid-tile>
        <mat-grid-tile>9.80€</mat-grid-tile>

        <mat-grid-tile colspan="2" ngbPopover="Jack Daniel's n°7, triple sec, jus de citron, sucre, limonade"
          popoverTitle="Manhatan" popoverClass="popover">Manhatan<mat-icon>info</mat-icon>
        </mat-grid-tile>
        <mat-grid-tile>6.80€</mat-grid-tile>
        <mat-grid-tile>9.80€</mat-grid-tile>

        <mat-grid-tile colspan="2" ngbPopover="Rhum, vodka, gin, tequila, triple sec, jus de citron, coca"
          popoverTitle="Long Island Iced Tea" popoverClass="popover">Long Island Iced Tea<mat-icon>info</mat-icon>
        </mat-grid-tile>
        <mat-grid-tile>6.80€</mat-grid-tile>
        <mat-grid-tile>9.80€</mat-grid-tile>

        <mat-grid-tile colspan="2" ngbPopover="Vodka, citron vert, ginger beer" popoverTitle="Moscou Mule"
          popoverClass="popover">
          Moscou Mule<mat-icon>info</mat-icon>
        </mat-grid-tile>
        <mat-grid-tile>6.80€</mat-grid-tile>
        <mat-grid-tile>9.80€</mat-grid-tile>

        <mat-grid-tile colspan="2" ngbPopover="Gin, citron vert, ginger beer" popoverTitle="London Mule"
          popoverClass="popover">
          London Mule<mat-icon>info</mat-icon>
        </mat-grid-tile>
        <mat-grid-tile>6.80€</mat-grid-tile>
        <mat-grid-tile>9.80€</mat-grid-tile>

        <mat-grid-tile colspan="2" ngbPopover="Apérol, prosseco" popoverTitle="Apérol Spritz" popoverClass="popover">
          Apérol Spritz
          <mat-icon>info</mat-icon>
        </mat-grid-tile>
        <mat-grid-tile>6.80€</mat-grid-tile>
        <mat-grid-tile>9.80€</mat-grid-tile>

        <mat-grid-tile colspan="2" ngbPopover="Suze, prosseco" popoverTitle="Suze Spritz" popoverClass="popover">Suze
          Spritz
          <mat-icon>info</mat-icon>
        </mat-grid-tile>
        <mat-grid-tile>6.80€</mat-grid-tile>
        <mat-grid-tile>9.80€</mat-grid-tile>
      </mat-grid-list>

      <h4>Cocktails pour les amateurs</h4>
      <mat-grid-list cols="2" rowHeight="20px">
        <mat-grid-tile colspan="2"></mat-grid-tile>

        <mat-grid-tile
          ngbPopover="Jack Daniel's Single Barrel, cointreau, jus de citron, purée de cesire, cherry bitter, ginger beer"
          popoverTitle="Spicy Jack" popoverClass="popover">Spicy Jack
          <mat-icon>info</mat-icon>
        </mat-grid-tile>
        <mat-grid-tile>13€</mat-grid-tile>

        <mat-grid-tile ngbPopover="Ballantime's, Laphroag quarter cask, sirop de miel, jus de citron, gingembre frais"
          popoverTitle="Penicillin" popoverClass="popover">Penicillin
          <mat-icon>info</mat-icon>
        </mat-grid-tile>
        <mat-grid-tile>13€</mat-grid-tile>

        <mat-grid-tile ngbPopover="Diplomatico Exclusiva 12 ans, cointreau, angostura, citron vert, sirop d'orgeat"
          popoverTitle="Diplomatico Maïthaï" popoverClass="popover">Diplomatico Maïthaï
          <mat-icon>info</mat-icon>
        </mat-grid-tile>
        <mat-grid-tile>13€</mat-grid-tile>

        <mat-grid-tile ngbPopover="Rhum Botran 15 ans, sucre, angostura" popoverTitle="Ron Old Fashioned"
          popoverClass="popover">Ron Old
          Fashioned
          <mat-icon>info</mat-icon>
        </mat-grid-tile>
        <mat-grid-tile>13€</mat-grid-tile>

        <mat-grid-tile ngbPopover="Gin Hendrick's, concombre, basilic, ginger" popoverTitle="Basilicum"
          popoverClass="popover">Basilicum
          <mat-icon>info</mat-icon>
        </mat-grid-tile>
        <mat-grid-tile>13€</mat-grid-tile>

        <mat-grid-tile ngbPopover="Gin Monkey47, campari, Vermouth rouge" popoverTitle="Negroni" popoverClass="popover">
          Negroni
          <mat-icon>info</mat-icon>
        </mat-grid-tile>
        <mat-grid-tile>13€</mat-grid-tile>
      </mat-grid-list>

      <h4>Long Drink</h4>
      <mat-grid-list cols="3" rowHeight="20px">
        <mat-grid-tile></mat-grid-tile>
        <mat-grid-tile [ngbPopover]="popContentHappyHour" [popoverTitle]="popTitleHappyHour" popoverClass="popover"
          container="body" class="wrap title">
          Happy Hour
          <mat-icon class="small">info</mat-icon>
        </mat-grid-tile>
        <mat-grid-tile class="title">Normal</mat-grid-tile>

        <mat-grid-tile>Rhum coca</mat-grid-tile>
        <mat-grid-tile>5.50€</mat-grid-tile>
        <mat-grid-tile>8.50€</mat-grid-tile>

        <mat-grid-tile>Whisky coca</mat-grid-tile>
        <mat-grid-tile>5.50€</mat-grid-tile>
        <mat-grid-tile>8.50€</mat-grid-tile>

        <mat-grid-tile>Gin tonic</mat-grid-tile>
        <mat-grid-tile></mat-grid-tile>
        <mat-grid-tile>9.50€</mat-grid-tile>

        <mat-grid-tile>Suze tonic</mat-grid-tile>
        <mat-grid-tile></mat-grid-tile>
        <mat-grid-tile>9.50€</mat-grid-tile>

        <mat-grid-tile>Vodka Red bull</mat-grid-tile>
        <mat-grid-tile></mat-grid-tile>
        <mat-grid-tile>9.50€</mat-grid-tile>
      </mat-grid-list>

      <mat-grid-list cols="1" rowHeight="20px">
        <mat-grid-tile></mat-grid-tile>
      </mat-grid-list>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <!-- <mat-icon class="tab-icon">thumb_up</mat-icon> -->
        Autres
      </ng-template>
      <h3>Softs</h3>
      <h4>Nos soft</h4>
      <mat-grid-list cols="3" rowHeight="20px">
        <mat-grid-tile></mat-grid-tile>
        <mat-grid-tile [ngbPopover]="popContentHappyHour" [popoverTitle]="popTitleHappyHour" popoverClass="popover"
          container="body" class="wrap title">
          Happy Hour
          <mat-icon class="small">info</mat-icon>
        </mat-grid-tile>
        <mat-grid-tile class="title">Normal</mat-grid-tile>

        <mat-grid-tile>Coca Cola</mat-grid-tile>
        <mat-grid-tile>3.50€</mat-grid-tile>
        <mat-grid-tile>5.00€</mat-grid-tile>

        <mat-grid-tile>Coca Cola Zéro</mat-grid-tile>
        <mat-grid-tile>3.50€</mat-grid-tile>
        <mat-grid-tile>5.00€</mat-grid-tile>

        <mat-grid-tile>Orangina</mat-grid-tile>
        <mat-grid-tile>3.50€</mat-grid-tile>
        <mat-grid-tile>5.00€</mat-grid-tile>

        <mat-grid-tile>Ice Tea</mat-grid-tile>
        <mat-grid-tile>3.50€</mat-grid-tile>
        <mat-grid-tile>5.00€</mat-grid-tile>

        <mat-grid-tile>Perrier</mat-grid-tile>
        <mat-grid-tile>3.50€</mat-grid-tile>
        <mat-grid-tile>5.00€</mat-grid-tile>

        <mat-grid-tile>Limonade</mat-grid-tile>
        <mat-grid-tile>3.50€</mat-grid-tile>
        <mat-grid-tile>5.00€</mat-grid-tile>

        <mat-grid-tile>Jus d'ananas</mat-grid-tile>
        <mat-grid-tile>3.50€</mat-grid-tile>
        <mat-grid-tile>5.00€</mat-grid-tile>

        <mat-grid-tile>Jus d'abricot</mat-grid-tile>
        <mat-grid-tile>3.50€</mat-grid-tile>
        <mat-grid-tile>5.00€</mat-grid-tile>

        <mat-grid-tile>Redbull</mat-grid-tile>
        <mat-grid-tile></mat-grid-tile>
        <mat-grid-tile>5.00€</mat-grid-tile>
      </mat-grid-list>

      <h3>Planches</h3>
      <h4>Nos planches</h4>
      <mat-grid-list cols="2" rowHeight="20px">
        <mat-grid-tile colspan="2"></mat-grid-tile>

        <mat-grid-tile>Fromage</mat-grid-tile>
        <mat-grid-tile>13.00€</mat-grid-tile>

        <mat-grid-tile>Charcuterie</mat-grid-tile>
        <mat-grid-tile>15.00€</mat-grid-tile>

        <mat-grid-tile>Mixte</mat-grid-tile>
        <mat-grid-tile>19.00€</mat-grid-tile>

        <mat-grid-tile>Mixte XXL</mat-grid-tile>
        <mat-grid-tile>36.00€</mat-grid-tile>
      </mat-grid-list>

      <h3>Autres alcools</h3>
      <h4>Nos Vins</h4>
      <mat-grid-list cols="2" rowHeight="20px">
        <mat-grid-tile></mat-grid-tile>
        <mat-grid-tile class="title">Verre</mat-grid-tile>

        <mat-grid-tile ngbPopover="Côtes du Rhône / Bordeaux «Château des Belles Murailles»" popoverTitle="Rouge"
          popoverClass="popover">Rouge
          <mat-icon>info</mat-icon>
        </mat-grid-tile>
        <mat-grid-tile>5.00€</mat-grid-tile>

        <mat-grid-tile>Blanc Chardonnay</mat-grid-tile>
        <mat-grid-tile>5.00€</mat-grid-tile>

        <mat-grid-tile>Blanc Chablis</mat-grid-tile>
        <mat-grid-tile>6.00€</mat-grid-tile>

        <mat-grid-tile ngbPopover="Côtes de Provence" popoverTitle="Rosé" popoverClass="popover">Rosé
          <mat-icon>info</mat-icon>
        </mat-grid-tile>
        <mat-grid-tile>5.00€</mat-grid-tile>
      </mat-grid-list>

      <h4>Champagne</h4>
      <mat-grid-list cols="2" rowHeight="20px">
        <mat-grid-tile></mat-grid-tile>
        <mat-grid-tile class="title">Bouteille</mat-grid-tile>

        <mat-grid-tile>Champagne MUM</mat-grid-tile>
        <mat-grid-tile>70.00€</mat-grid-tile>
      </mat-grid-list>

      <h4>Nos digestifs</h4>
      <mat-grid-list cols="2" rowHeight="20px">
        <mat-grid-tile colspan="2"></mat-grid-tile>

        <mat-grid-tile>Get 27</mat-grid-tile>
        <mat-grid-tile>6.00€</mat-grid-tile>

        <mat-grid-tile>Bailey's</mat-grid-tile>
        <mat-grid-tile>6.00€</mat-grid-tile>

        <mat-grid-tile>Amaretto</mat-grid-tile>
        <mat-grid-tile>6.00€</mat-grid-tile>

        <mat-grid-tile>Cognac</mat-grid-tile>
        <mat-grid-tile>6.00€</mat-grid-tile>
      </mat-grid-list>

      <h3>Rhum ou Whisky (+400 choix demandez la carte)</h3>
      <mat-grid-list cols="1" rowHeight="20px">
        <mat-grid-tile></mat-grid-tile>
      </mat-grid-list>
    </mat-tab>
  </mat-tab-group>
</section>

<footer>
  <p>
    34 Rue de Lappe 75011 Paris<br />
    01 40 21 01 82
  </p>
  <a href="https://www.facebook.com/Le-gamin-bastille-192029424176171">
    <mat-icon>facebook</mat-icon>
  </a>
</footer>